<template>
  <table class="table">
    <thead>
      <tr>
        <th scope="col">
          SN
        </th>
        <th scope="col">
          Group Name
        </th>
        <th scope="col">
          Reading
        </th>
        <th scope="col">
          Listening
        </th>
        <th scope="col">
          OE-Speaking
        </th>
        <th scope="col">
          OE-Writing
        </th>
        <th scope="col">
          Typing
        </th>
        <th scope="col">
          Pronunciation
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(group,key) in data"
        :key="key"
      >
        <th scope="row">
          {{ key+1 }}
        </th>
        <th scope="row">
          {{ group.group_name }}
        </th>
        <td
          v-for="skill_type,index in skills"
          :key="index"
        >
          <span v-if="group[skill_type]">
            <div
              v-for="skill in group[skill_type]"
              :key="skill.id"
            >
              <router-link
                :to="{ path: `/${AUTH_USER().usertype}/problem/edit`, query: { problem_id: skill.id }}"
              >
                {{ skill.name }}
              </router-link>
              <p class="text-muted font-italic small mb-0">{{ skill.problem_type.name }}</p>
            </div>
          </span>
        </td>
      </tr>

    </tbody>
  </table>
</template>

<script>

export default {
  components: {
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      skills: ['reading', 'listening', 'speaking', 'writing', 'typing', 'pronunciation'],
    }
  },
}
</script>
